import React, { useState } from 'react';
import './CollapsibleText.css'; // Ensure your CSS file path is correct

function CollapsibleText({ text, collapse_thr = 100 }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const copyTextToClipboard = async () => {
    // First, try using the Clipboard API
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
        alert('Text copied to clipboard!');
        return; // Exit if successful
      } catch (err) {
        console.error('Failed to copy with navigator.clipboard: ', err);
        // Don't return here; fall back to the alternative method
      }
    }
    
    // Fallback method for browsers where navigator.clipboard.writeText is not available or fails
    const textArea = document.createElement('textarea');
    textArea.value = text;
    
    // Make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-9999px';
    textArea.style.top = '-9999px';
    
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'Successfully copied to clipboard using fallback method.' : 'Failed to copy using fallback method.';
      console.log(msg);
      alert(msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    
    document.body.removeChild(textArea);
  };  

  const goToURLWithPrompt = () => {
    // Construct URL with prompt parameter
    const url = `/Anything?prompt=${encodeURIComponent(text)}`;
    // Open URL in a new tab
    window.open(url, '_blank');
  };

  return (
    <div className="collapsible-text">
      <p>
        {isCollapsed ? `${text.slice(0, collapse_thr)}...` : text}
      </p>
      <div className="controls">
        {text.length > collapse_thr && (
          <button onClick={toggleCollapse} className="toggle-button">
            {isCollapsed ? 'Show More' : 'Show Less'}
          </button>
        )}
        <button onClick={copyTextToClipboard} className="copy-button">
          Copy
        </button>
        <button onClick={goToURLWithPrompt} className="copy-button">
          畫圖
        </button>
      </div>
    </div>
  );
}

export default CollapsibleText;
