import React, { useState, useRef, useEffect } from 'react';
import './BuddhaApp.css';
import SocialLinks from '../../components/SocialLinks.js'; // Adjust the path according to your file structure
import LoadingBar from '../../components/LoadingBar.js'
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import { APP_ID_BUDDHA } from '../../components/AppIds.js';
import buddhaHomeImage from '../../assets/images/buddha.webp';
import { buddhaPrompts } from './buddha-prompt.js';
import GenerateCount from '../../components/GenerateCount.js';
import ErrorPage from '../../components/ErrorPage.js';

const getTextsForLanguage = (lang) => {
  const defaultTexts = {
    title: "自在圓滿APP",
    placeholder: "例如：水彩畫風格，慈祥僧人，竹林中冥想",
    exploreButton: "送出"
  };

  return defaultTexts; // Fallback to default texts if language is not supported
};

function BuddhaApp() {
  const { incrementGenerateCount, getGenerateCount, delayByGenerateCount } = GenerateCount();
  const [inputText, setInputText] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(0);

  const userLang = navigator.language || navigator.userLanguage;
  
  const { title, placeholder, exploreButton } = getTextsForLanguage(userLang);
  const [model, setModel] = useState('');
  const [quality, setQuality] = useState('');
  const [errorText, setErrorText] = useState('');

  const textareaRef = useRef(null);

  const adjustTextAreaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleSelfDefine = () => {
    setInputText('');
  };

  const handlePrompt = () => {
    const randomIndex = Math.floor(Math.random() * buddhaPrompts.length);
    setInputText(buddhaPrompts[randomIndex]);
  };

  const handleRandom = async (event) => {
    const randomIndex = Math.floor(Math.random() * buddhaPrompts.length);
    setInputText(buddhaPrompts[randomIndex]);
    
    // Call handleSubmit after handlePrompt to generate an image based on the selected prompt
    await handleSubmit(event, buddhaPrompts[randomIndex]);
  };

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const handleSubmit = async (event, promptParam) => {
    event.preventDefault();
    setIsLoading(true);
    setImageSrc(null);
    setErrorText('');
    const prompt = promptParam ? promptParam : `${inputText}`;
    const startTime = new Date();
    incrementGenerateCount();
    const delay = delayByGenerateCount();
    await sleep(delay * 1000); 
    if (textareaRef.current) textareaRef.current.blur();
    try {
      const response = await fetch(`${hostname}/generate-image`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          prompt: prompt,
          n: 1,
          size: '1024x1024',
          app_id: APP_ID_BUDDHA
        }),
      });
      setIsLoading(false);

      if (response.ok) {
        const endTime = new Date(); 
        const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
        setDuration(durationSec);
        const result = await response.json();
        setImageSrc(result.image_url);
        setModel(result.model);
        setQuality(result.quality);

        // Scroll to the submit button
        const submitButton = document.querySelector(".submit-button");
        if (submitButton) {
          submitButton.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
      } else {
        // Handle HTTP errors
        const errorData = await response.json();
        const errorMessage = `Code ${errorData.code}: ${errorData.msg.substring(0, 60)}...`;
        setErrorText(errorMessage);
        console.error('Failed to generate image:', response.statusText);
      }
    } catch (error) { //ERR_CONNECTION_REFUSED
      await sleep(5000); 
      const errorMessage = `Code 503: Server down...`;
      setIsLoading(false)
      setErrorText(errorMessage);
    }
  };

  const downloadImage = async () => {
    if (imageSrc) {
      // Check if the navigator.share() API is available (for iOS)
      if (navigator.share) {
        try {
          // Share image using navigator.share() API (for iOS)
          const response = await fetch(imageSrc);
          const blob = await response.blob();
          const file = new File([blob], 'finaaidesign.png', { type: 'image/png' });
    
          await navigator.share({
            title: 'Fina AI Design Image',
            files: [file],
          });
        } catch (error) {
          console.error('Error sharing image:', error);
        }
      } else {
        // Create a download link (for desktop)
        const a = document.createElement('a');
        a.href = imageSrc;
        a.download = 'finaaidesign.png';
      
        // Append the link to the body and trigger a click event
        document.body.appendChild(a);
        a.click();
      
        // Cleanup
        document.body.removeChild(a);
      }
    }
  };
  

  useEffect(() => {
    adjustTextAreaHeight(textareaRef.current);
  }, [inputText]);

  useEffect(() => {
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_BUDDHA,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []);

  return (
    <div className="Buddha-App">
      <Helmet>
        <title>自在圓滿APP</title>
        <meta name="description" content="描繪你心中的佛, 生成式AI輔助, Generative AI Buddha portrait, Gen AI" />
        <meta property="og:title" content="自在圓滿APP" />
        <meta property="og:description" content="描繪你心中的佛, 生成式AI輔助, Generative AI Buddha portrait, Gen AI" />
        <meta property="og:url" content="https://app.finaaidesign.com/buddha" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.finaaidesign.com/buddha.jpg" />
        <meta property="og:locale" content="zh_TW" />
      </Helmet>
      <header className="Buddha-App-header">
        <h1>{title}</h1>
        <h4>生成式AI，描繪你心中的美景</h4>
        {errorText && <ErrorPage errorText={errorText} />}
        {imageSrc && (
          <>
            <img src={imageSrc} alt="Generated" className="generated-image" />
            <button className="custom-button" onClick={downloadImage}>下載</button>
          </>
        )}
        <div className="button-row">
          <button className="custom-button" onClick={handleSelfDefine}>自行創作</button>
          <button className="custom-button" onClick={handlePrompt}>文本建議</button>
          {/* <button className="custom-button" onClick={handleRandom} disabled={isLoading}>隨機隨緣</button> */}
        </div>
        <div className="form-container-buddha">
          <textarea
            value={inputText}
            onChange={(e) => {
              setErrorText('');
              setInputText(e.target.value);
              adjustTextAreaHeight(e.target);
            }}
            placeholder={placeholder}
            className="text-area"
            ref={textareaRef}
          />
          <button type="button" onClick={handleSubmit} disabled={isLoading} className="submit-button">
            {exploreButton}
          </button>
        </div>
        {!isLoading && !imageSrc && !errorText && (
          <div>
            <img src={buddhaHomeImage} alt="AI generated buddha portrait" className="buddha-home-image" />
          </div>
        )}

        {isLoading && <LoadingBar 
                backgroundColor="#FFC0CB"
                containerBackgroundColor="#FFFFFF" />}
        <SocialLinks />
        {imageSrc && (
          <>
            <div>Duration: {model},{quality},{duration}s</div>
          </>
        )}
      </header>
    </div>
  );
}  

export default BuddhaApp;
