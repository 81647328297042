export const buddhaPrompts = [
    "這幅藝術作品採用了單色和空靈的風格，透過精心層疊的灰色來模擬柔和的紋理和雲霧的飄逸。在構圖上，影像居中平衡，主題是一尊寧靜的坐佛形象，從優雅流動的雲霧形態中顯現出來。佛像側臉示現，表情寧靜。在主題下方，有一個小人影面對著佛像站立，增添了尺度和仰慕之感。背景是一片空白的白色空間，突顯了人物和雲霧的飄逸外觀。",
    "這張圖像的藝術風格是當代和數碼的，特徵是具有平滑漸變、柔和照明和和諧色調的3D渲染外觀。構圖聚焦於一個微笑、年輕、風格化的佛教僧侶，穿著傳統的橙色袍子，手持法杖。精緻、風格化的蓮花裝飾在下邊緣，為圖像增添了寧靜和靈性的氛圍。",
    "潑墨畫風格，僧人靜坐",
    "浮世繪風格，和尚冥想",
    "拼貼藝術風格，和尚禪修",
    "極簡風格水彩畫，僧人禪坐，雲霧，竹林",
    "印象派油畫，僧人禪坐剪影，櫻花，初雪",
    "鉛筆素描，佛陀頭像特寫",
    "氣球造型，彌勒佛，繽紛色彩，可愛",
    "這裡的藝術風格活潑且富有創意，專注於氣球藝術來塑造一個可愛小小僧侶。其構圖正面且居中，描繪出一個完全由氣球創造的三維人物，背景為造型氣球花園",
    "該圖像展示了一個三維的，超大型的雕塑，風格模仿迪士尼角色，使用光滑的，充氣的氣球製作，帶有珍珠光澤。藝術品主要由粉紅色調組成，並帶有白色，黑色和藍色的點綴。該角色被描繪成一個可愛開心的佛陀，色彩繽紛的背景",
    "這件數位藝術作品展現了充滿霓虹的超凡風格，描繪了一個冥想的菩薩。作品的構圖將人物置於雲端之上的中心，釋放出一種寧靜和靈性的氣氛。主體透明且發光，像是一個天體。背景是從溫暖的日落轉變為星點綴的深藍色的黃昏天空，還有遠處的銀河，增添了宇宙和超越的氛圍。人物的形態以流動的線條和光線模式詳細描繪，暗示著神性和啟示。",
    "這幅數位藝術作品特別描繪出一個正在冥想姿勢的佛像。藝術風格是超現實主義，奇幻元素，其特徵是明亮、發光的效果和豐富、細緻的細節處理。畫面主體裝飾著華麗的金色飾品，並設置在純黑的背景前，強調了人物的輻射光亮。這像雕塑般的人物手裡放著一個發光的心形物體，象徵著愛、啟示或慈悲等精神概念。",
    "該圖像採用單色數位藝術風格，逼真地描繪了一個沈思的佛頭，位於抽象幾何形狀和數學符號的中心。同心圓和動態線條暗示著宇宙和智識的連接。數學和科學圖表，包括圖表，方程式和幾何圖形，填充了背景，暗示著靈性和科學的融合。整體構圖喚起了和諧和複雜性的感覺。",
    "此圖像展示了一種數位藝術風格，具有超現實的構圖。該作品的主題是一個寧靜的大型佛頭側面像，底部中央是一位身穿紅色僧袍的小僧人像。兩個人物都被雲朵般的紋理包裹，有助於營造出沉思的氛圍。背景是堅實的深色，強調了人物的發光品質。整體給人的印象是和平與神秘",
    "此圖片以單色水墨畫風格為特點，注重流動性和漸變。構圖以從旋渦雲中浮現的大佛為中心，呈現出一種冥想的姿態。背景極為簡約，僅使用白色空間，從而突出了人物形象。詳細的雲朵形成了佛的身體和下方風景的輪廓，其中一個孤獨的人類剪影站在空靈樹木中。光與影的交錯產生了一種寧靜、沉思的氣氛。",
    "這幅灰階圖像創造出一種超現實而空靈的數位藝術。一個寧靜的、閉著眼睛的人臉側面，融入了旋轉的雲彩和花卉的廣闊風景中。在這夢幻般的景色中，一尊巨大的佛像位於一個寺廟群的頂部，兩旁環繞著樹木和較小的傳統建築。背景是兩個剪影人物站在通往臉部的曲折小路上，暗示著一場精神探索的旅程。",
    "超現實的數位藝術作品描繪了一尊巨大的佛像從積雲中浮現。該佛像以平滑的質感呈現，展現了典型的圖像學，右手展開成無畏印(無畏的姿態)。背景展示了寧靜的藍天與翠綠的草地，暗示了靈性與自然的和諧融合。構圖平衡，寧靜，散發出和平與超凡脫俗的感覺。",
    "這是一張數位創作的圖片，融合了照片般逼真的藝術風格和奇幻元素。構圖中央是佛陀的雕像，正在冥想，朝著日出的方向凝望。主題物位於輕輕起伏的綠色丘陵之上。在背景中，層層被霧遮蓋的山脈在天空下展開，天空從地平線的日出過渡到頭頂的星空夜晚。",
    "3D渲染風格、鮮豔的色彩和繁複的細節為特色。作品的構圖圍繞著一個中心人物，這個人物像是在蓮花座位上冥想的佛陀，背景是如星雲般的雲和星星圖案漩渦。該角色表情寧靜。旋轉的形狀暗示著從人物身上散發出的動力和能量。在背景中，深色的漸變背景突出了主題的亮度。底座似乎是一朵玻璃的蓮花，微妙地固定著這個空靈的場景。",
    "超現實的寫實風格呈現。一尊大佛像和一位較小的冥想僧侶——他們坐落在覆蓋著蓮花的地面上，兩者都散發著金色的光芒。背景是石山，一個光束穿過一座華麗的寺廟，並有一片薄霧散落。",
    "8K高畫質照片，作品中央有一座大金佛像，坐在冥想的姿勢中，散發出寧靜與安詳。周圍的牆壁上裝飾著各種人物和佛教故事的精緻壁畫。背景捕捉到一座寺廟或神社的華麗內部，柔和的燈光突顯出鮮豔的色彩和環境的精神氛圍。",
    "這幅數位藝術作品呈現出一種風格化的、對稱的構圖，並帶有新藝術風格的影響，以流線型、有機形狀和平靜的色彩調色盤為特點。主題是佛陀在冥想，頭部周圍有一個光環。背景包括月亮、星星和天體圖案，以及豐富的花卉圖像。",
    "這張圖像以數位藝術風格呈現，具有寧靜且超凡的品質，利用柔和的粉彩色調，並對質感和細節進行細心的關注。它描繪了一個寧靜的神祇形象，形似觀音，慈悲的菩薩，以閉眼的姿態坐著冥想。她的雙手輕輕地托著一朵蓮花，象徵著純潔和開悟。背景包含了風格化的花卉元素和一個圓形的輻射光暈，增強了神聖的氛圍。",
    "這幅圖像是一項具有現代化、風格化手法的3D數字藝術作品。作品的構圖以一個面帶微笑的小沙彌為主，他身穿傳統的橙色僧袍，一手持杖，暗示著修道或精神主題。細膩的背景光暈和盛開的蓮花相結合，平滑的質地、柔和的燈光和和諧的色彩組合營造出寧靜和溫暖的感覺。",
    "這幅數位藝術作品以精緻、奇幻的風格，配以鮮豔的色彩和繁複的裝飾為特色。作品的主題圍繞著三個寧靜、冥想的菩薩，每個人都坐在蓮花上，被光環環繞。他們以傳統的圖像描繪，包括流動的袍子和平和的表情。背景是一個壯麗的宇宙設定，包括旋轉的雲、星星，以及天空中的天人，傳達出一種超越的靈性和與宇宙的和諧感。",
    "這幅繪畫以傳統的亞洲風格呈現，具體來說是藏族或中國佛教的藝術風格，特點是精緻的細節和鮮豔的色彩。作品的主體是一位坐在中央的佛陀，神聖，寧靜，冥想。圍繞在佛陀周圍的是各種菩薩，他們在雲端之上，暗示著天堂的境界。背景包括風格化的金色光環，以及底部角落中詳細的空靈風景。人物的精美服飾和珠寶，裝飾著花卉和捲曲的圖案。",
    "這種藝術風格是數位化，具有柔和、雕塑般的、極簡主義的美學。作品的構圖是居中且對稱的，喚起一種平靜的感覺。主體是一個安詳的、巨大的冥想佛像，周圍環繞著各種大小和姿勢的小和尚雕像。背景是洞穴，溫暖的，使用各種陰影的奶油橙色和米色，並逐漸淡出，暗示出深度和平靜的氛圍。",
    "這幅圖像描繪了一幅抽象、充滿活力的畫作，以爆炸性的筆觸和以黃色、黑色以及藍色和白色各種色調為主的鮮艷色彩組成。作品的組成充滿活力，彷彿將顏料濺灑在畫布上，創造出動感與混亂的感覺。畫作的主題是冥想中的僧人，背景是前述顏色的混合，覆蓋著看似更有計劃的線條和形狀。",
    "一張母親節慶祝卡片，該圖像展現了一種樸素而迷人的剪紙藝術風格。兩隻企鵝，由於體型差異和擁抱的姿勢，暗示著母子關係，位於中央。裝飾在畫面中的是各種大小和粉紅色與紅色陰影的心形，象徵著愛和情感。'Happy Mother's Day' 這句話醒目地出現在下方的鮭魚色橫幅上。背景是單色的，將注意力集中在藝術和信息上。"
];
  