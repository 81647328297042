import React, { useState, useRef, useEffect } from 'react';
import './CardApp.css';
import SocialLinks from '../../components/SocialLinks.js'; // Adjust the path according to your file structure
import LoadingBar from '../../components/LoadingBar.js'
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import { APP_ID_CARD } from '../../components/AppIds.js';
import CardHomeImage from '../../assets/images/cardApp.jpg';
import { AnimalPrompts } from './card-prompt.js';
import GenerateCount from '../../components/GenerateCount.js';

const getTextsForLanguage = (lang) => {
  const defaultTexts = {
    title: "菲娜賀卡APP",
    placeholder: "例如：企鵝、博美犬、獅子",
    exploreButton: "送出"
  };

  return defaultTexts; // Fallback to default texts if language is not supported
};

function CardApp() {
  const { incrementGenerateCount, getGenerateCount, delayByGenerateCount } = GenerateCount();
  const [inputText, setInputText] = useState('');
  const [inputName, setInputName] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(0);

  const userLang = navigator.language || navigator.userLanguage;
  
  const { title, placeholder, exploreButton } = getTextsForLanguage(userLang);

  const textareaRef = useRef(null);

  const adjustTextAreaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleSelfDefine = () => {
    setInputText('');
    setInputName('');
  };

  const handlePrompt = () => {
    const randomIndex = Math.floor(Math.random() * AnimalPrompts.length);
    setInputText(AnimalPrompts[randomIndex]);
  };

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const cardText = (inputName) => {
    if (!inputName || inputName.trim() === '') {
      return "Happy Mother's Day";
    } else {
      return `Dear ${inputName.trim()}, Happy Mother's Day`;
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setImageSrc(null);
    const animal = `${inputText}`;
    const prompt = `一張母親節慶祝卡片，該圖像展現了一種樸素而迷人的剪紙藝術風格。兩隻${animal}，由於體型差異和擁抱的姿勢，暗示著母子關係，位於中央。裝飾在畫面中的是各種大小和粉紅色與紅色陰影的心形，象徵著愛和情感。'${cardText(inputName)}' 這句話醒目地出現在下方的鮭魚色橫幅上。背景是單色的，將注意力集中在藝術和信息上。`;
    const startTime = new Date();
    incrementGenerateCount();
    const delay = delayByGenerateCount();
    await sleep(delay * 1000); 
    if (textareaRef.current) textareaRef.current.blur();
    const response = await fetch(`${hostname}/generate-image`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        prompt: prompt,
        n: 1,
        size: '1024x1024',
        app_id: APP_ID_CARD
      }),
    });
    setIsLoading(false);

    if (response.ok) {
      const endTime = new Date(); 
      const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
      setDuration(durationSec);
      const result = await response.json();
      setImageSrc(result.image_url);

      // Scroll to the submit button
      const submitButton = document.querySelector(".submit-button");
      if (submitButton) {
        submitButton.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    } else {
      console.error('Failed to generate image:', response.statusText);
    }
  };

  const downloadImage = async () => {
    if (imageSrc) {
      // Check if the navigator.share() API is available (for iOS)
      if (navigator.share) {
        try {
          // Share image using navigator.share() API (for iOS)
          const response = await fetch(imageSrc);
          const blob = await response.blob();
          const file = new File([blob], 'finaaidesign.png', { type: 'image/png' });
    
          await navigator.share({
            title: 'https://app.finaaidesign.com/card',
            files: [file],
          });
        } catch (error) {
          console.error('Error sharing image:', error);
        }
      } else {
        // Create a download link (for desktop)
        const a = document.createElement('a');
        a.href = imageSrc;
        a.download = 'finaaidesign.png';
      
        // Append the link to the body and trigger a click event
        document.body.appendChild(a);
        a.click();
      
        // Cleanup
        document.body.removeChild(a);
      }
    }
  };
  

  useEffect(() => {
    adjustTextAreaHeight(textareaRef.current);
  }, [inputText]);

  useEffect(() => {
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_CARD,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []);

  return (
    <div className="Card-App">
      <Helmet>
        <title>菲娜賀卡APP</title>
        <meta name="description" content="生成式AI, 母親節賀卡創作, 生成式AI輔助, Generative AI, Gen AI" />
        <meta property="og:title" content="菲娜賀卡APP" />
        <meta property="og:description" content="生成式AI, 母親節賀卡創作, 生成式AI輔助, Generative AI, Gen AI" />
        <meta property="og:url" content="https://app.finaaidesign.com/buddha" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.finaaidesign.com/buddha.jpg" />
        <meta property="og:locale" content="zh_TW" />
      </Helmet>
      <header className="Card-App-header">
        <h1>{title}</h1>
        <h4>生成式AI，母親節賀卡創作</h4>
        {imageSrc && (
          <>
            <img src={imageSrc} alt="Generated" className="generated-image" />
            <button className="custom-card-button" onClick={downloadImage}>下載</button>
          </>
        )}
        <div className="button-row">
          <button className="custom-card-button" onClick={handleSelfDefine}>自行創作</button>
          <button className="custom-card-button" onClick={handlePrompt}>隨機主題</button>
        </div>
        <div className="form-container-buddha">
          <input
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
              adjustTextAreaHeight(e.target);
            }}
            placeholder={placeholder}
            className="text-area"
            ref={textareaRef}
          />
          <input
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
            placeholder="媽媽英文名字，可空白"
            className="text-area"
          />
          <button type="button" onClick={handleSubmit} disabled={isLoading} className="submit-button">
            {exploreButton}
          </button>
        </div>
        {!isLoading && !imageSrc && (
          <div>
            <img src={CardHomeImage} alt="AI generated buddha portrait" className="buddha-home-image" />
          </div>
        )}

        {isLoading && <LoadingBar 
                backgroundColor="#FFC0CB"
                containerBackgroundColor="#FFFFFF" />}
        <SocialLinks />
        {imageSrc && (
          <>
            <div>Duration: {duration}s</div>
          </>
        )}
      </header>
    </div>
  );
}  

export default CardApp;
