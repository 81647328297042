import React, { useState, useRef, useEffect } from 'react';
import './NinjaApp.css'; // Ensure you have NinjaApp.css for styling
import SocialLinks from '../../components/SocialLinks.js';
import LoadingBar from '../../components/LoadingBar.js';
import { hostname } from '../../components/config.js';
import { Helmet } from 'react-helmet';
import { APP_ID_NINJA } from '../../components/AppIds.js';
import CollapsibleText from '../../components/CollapsibleText.js';
import { cropImageToSquare } from '../../components/ImageUtils.js';
import ninjaHomeImage from '../../assets/images/NinjaAnimated.gif';

const getTextsForLanguage = (lang) => {
  // Adjust or expand the language support as needed
  const texts = {
    // Assuming default and 'zh' texts are appropriate, add or modify as needed
    default: { uploadPrompt: "AI ImageEcho", buttonText: "Analyze Image", subtitle: "revert prompt and generate similar image" },
    'zh': { uploadPrompt: "拷貝忍者菲娜娜", buttonText: "分析圖片",subtitle: "反向生成咒語及相似圖" },
    'th': { uploadPrompt: "อัปโหลดรูปภาพ", buttonText: "วิเคราะห์ภาพ",subtitle: "สร้างภาพลักษณ์ที่คล้ายคลึงกัน" },
  };

  if (lang.startsWith('zh')) return texts['zh'];
  if (lang.startsWith('th')) return texts['th'];
  return texts.default;
};

function NinjaApp() {
  const version = "v1.02";
  const [imageFile, setImageFile] = useState(null);
  const [responseText, setResponseText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userLang = navigator.language || navigator.userLanguage;
  const { uploadPrompt, buttonText, subtitle } = getTextsForLanguage(userLang);
  const [imageSrc, setImageSrc] = useState('');
  const [duration, setDuration] = useState(0);
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const responseTextRef = useRef(null);
  const [model, setModel] = useState('');
  const [quality, setQuality] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setImageFile(file); // Get the first file
    cropImageToSquare(file, (newImageFile) => {
      const newFileUrl = URL.createObjectURL(newImageFile);
      setOriginalImageUrl(newFileUrl); // Assuming setOriginalImageUrl does the same
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!imageFile) {
      alert('Please select an image file first.');
      return;
    }

    setIsLoading(true);
    setImageSrc(null);
    setResponseText(null);
    const startTime = new Date();
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const response = await fetch(`${hostname}/read-image`, {
        method: 'POST',
        body: formData, // Send the file within formData
      });

      if (response.ok) {
        const result = await response.json();  
        const promptContent = result.choices[0].message.content;
        setResponseText(promptContent); 

        // Second API call: Generate an image based on the prompt content
        const response2 = await fetch(`${hostname}/generate-image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            prompt: promptContent,
            n: 1,
            size: '1024x1024', // Adjust based on the API's expected format
            app_id: APP_ID_NINJA // Make sure to use the correct APP_ID
          }),
        });
        const result2 = await response2.json();
        if (!response2.ok) throw new Error('Failed to generate image');
        const imageUrl = result2.image_url; // Adjust this based on the actual key in the response
        setImageSrc(imageUrl); // Display the generated image
        setModel(result2.model);
        setQuality(result2.quality);
        const endTime = new Date(); 
        const durationSec = ((endTime - startTime) / 1000).toFixed(1); 
        setDuration(durationSec);
      } else {
        console.error('Failed to analyze image:', response.statusText);
        setResponseText('Failed to analyze image.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseText('Error analyzing image.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (imageSrc && responseTextRef.current) {
      // Scroll the CollapsibleText component into view
      responseTextRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [imageSrc]); // Dependency array ensures effect runs only when responseText changes
  
  useEffect(() => {
    // Call the backend API to increment visit statistics
    const incrementVisitCount = async () => {
        try {
            const response = await fetch(`${hostname}/visit`, {
                method: 'POST', // Assuming POST request is required to increment the count
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  app_id: APP_ID_NINJA,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Optional: Handle the response data
            const data = await response.json();
            console.log('Visit incremented:', data);
        } catch (error) {
            console.error('Failed to increment visit count:', error);
        }
    };

    incrementVisitCount();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="Ninja-App">
      <Helmet>
        <title>拷貝忍者菲娜娜, AI ImageEcho</title>
        <meta name="description" content="反向生成咒語及相似圖, revert prompt and generate similar image, #fina3099" />
      </Helmet>
      <header className="Ninja-App-header">
        <h1>
          {uploadPrompt} <span className="version-number">{version}</span>
        </h1>
        <h4>{subtitle} by <a href="https://openai.com/index/hello-gpt-4o/">GPT-4o</a></h4>
        <form onSubmit={handleSubmit} className="image-upload-form">
          <input 
            type="file" 
            onChange={handleFileChange} 
            accept="image/*" // Accept images only
          />
          <button type="submit" disabled={isLoading}>{buttonText}</button>
        </form>
        {isLoading && <LoadingBar backgroundColor="#FFC0CB" containerBackgroundColor="#FFFFFF" />}
        {imageSrc && (
          <div className="images-container">
            <div className="image-wrapper">
              <img src={originalImageUrl} alt="Original" className="generated-image" />
              <div>Original Image</div>
            </div>
            <div className="image-wrapper">
              <img src={imageSrc} alt="Generated" className="generated-image" />
              <div>Generated Image</div>
            </div>
          </div>
        )}
        {imageSrc && (<div>Duration: {model},{quality},{duration}s</div>)}
        {!isLoading && !imageSrc && (
          <>
            <img src={ninjaHomeImage} alt="Generated" className="generated-image" />
          </>
        )}
        {imageSrc && (
          <div ref={responseTextRef} className="response-text-container">
            <CollapsibleText text={responseText} />
          </div>
        )}
        <SocialLinks />
      </header>
    </div>
  );
}

export default NinjaApp;
