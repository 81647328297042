export const AnimalPrompts = [
    "北極熊",
    "蘇格蘭摺耳貓",
    "英國短毛貓",
    "波斯貓",
    "金吉拉貓",
    "海豹貓",
    "柴犬",
    "拉布拉多狗",
    "法國鬥牛犬",
    "博美犬",
    "柯基犬",
    "大象",
    "知更鳥",
    "長頸鹿",
    "兔子",
    "企鵝",
    "貓頭鷹",
    "無尾熊",
    "松鼠",
    "刺蝟",
    "狐狸",
    "天竺鼠",
    "鹿",
    "鯨魚",
    "鯊魚",
    "海豚",
    "海龜",
    "蜜蜂",
    "獅子",
    "老虎",
    "機器人",
    "水母",
    "泰迪熊"
];
  