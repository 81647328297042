import React from 'react';
import errorImage1 from '../assets/images/error/001.webp';
import errorImage2 from '../assets/images/error/002.webp';
import errorImage3 from '../assets/images/error/003.webp';
import errorImage4 from '../assets/images/error/004.webp';
import errorImage5 from '../assets/images/error/005.webp';
import errorImage6 from '../assets/images/error/006.webp';
import errorImage7 from '../assets/images/error/007.webp';
import errorImage8 from '../assets/images/error/008.webp';
import errorImage9 from '../assets/images/error/009.webp';
import errorImage10 from '../assets/images/error/010.webp';
import errorImage11 from '../assets/images/error/011.webp';
import errorImage12 from '../assets/images/error/012.webp';
import errorImage13 from '../assets/images/error/013.webp';
import errorImage14 from '../assets/images/error/014.webp';
import errorImage15 from '../assets/images/error/015.webp';
import errorImage16 from '../assets/images/error/016.webp';
import errorImage17 from '../assets/images/error/017.webp';
import errorImage18 from '../assets/images/error/018.webp';
import errorImage19 from '../assets/images/error/019.webp';
import errorImage20 from '../assets/images/error/020.webp';
import errorImage21 from '../assets/images/error/021.webp';
import errorImage22 from '../assets/images/error/022.webp';
import errorImage23 from '../assets/images/error/023.webp';
import errorImage24 from '../assets/images/error/024.webp';
import errorImage25 from '../assets/images/error/025.webp';
import errorImage26 from '../assets/images/error/026.webp';

const errorImages = [
    errorImage1,
    errorImage2,
    errorImage3,
    errorImage4,
    errorImage5,
    errorImage6,
    errorImage7,
    errorImage8,
    errorImage9,
    errorImage10,
    errorImage11,
    errorImage12,
    errorImage13,
    errorImage14,
    errorImage15,
    errorImage16,
    errorImage17,
    errorImage18,
    errorImage19,
    errorImage20,
    errorImage21,
    errorImage22,
    errorImage23,
    errorImage24,
    errorImage25,
    errorImage26,   
]
const ErrorPage = ({ errorText }) => {
  // Randomly select an error image
  const randomImage = errorImages[Math.floor(Math.random() * errorImages.length)];


  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div>
        <img
          src={randomImage}
          alt="Error"
          style={{ width: '60%', height: 'auto', borderRadius: '10px' }}
        />
      </div>
      <p style={{ color: 'red' }}>系統錯誤，請稍後再試</p>   
      <p style={{ color: 'red' }}>{errorText}</p> 
    </div>
  );
};

export default ErrorPage;
