// src/utils/imageUtils.js

/**
 * Crops an image file to a square shape and calls the callback with a new image file.
 * @param {File} file The original image file.
 * @param {Function} callback The callback to call with the new image file.
 */
export const cropImageToSquare = (file, callback) => {
    const img = new Image();
    const objectURL = URL.createObjectURL(file);
    img.onload = () => {
      let [newWidth, newHeight] = [img.width, img.height];
      let [startX, startY] = [0, 0];
  
      // Calculate dimensions to crop to square
      if (img.width !== img.height) {
        const squareSide = Math.min(img.width, img.height);
        newWidth = newHeight = squareSide;
        startX = (img.width - squareSide) / 2;
        startY = (img.height - squareSide) / 2;
      }
  
      // Create an off-screen canvas
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');
  
      // Draw the image (or part of it) on the canvas
      ctx.drawImage(img, startX, startY, newWidth, newHeight, 0, 0, newWidth, newHeight);
  
      // Convert canvas to blob and then to File
      canvas.toBlob((blob) => {
        const newFile = new File([blob], file.name, {type: file.type});
        callback(newFile);
      }, file.type);
    };
  
    img.src = objectURL;
  };
  