import React, { useState, useEffect } from 'react';
import './LoadingBar.css';
import { hostname } from './config';

function LoadingBar({ backgroundColor, containerBackgroundColor }) {
    // Apply the backgroundColor prop to the style of the loading bar
    const barStyle = {
        backgroundColor: backgroundColor || '#4caf50', // Default color if none is provided
    };

    const [currentImageUrl, setCurrentImageUrl] = useState('');
    
    const instagramProfileUrl = "https://www.instagram.com/travelfina3099";


    useEffect(() => {
        const fetchRandomImage = async () => {
            try {
                const response = await fetch(`${hostname}/random-image`);
                const data = await response.json();
                //console.log("url="+data.imageUrl);
                setCurrentImageUrl(`${hostname}/${data.imageUrl}`);
            } catch (error) {
                console.error('Error fetching random image:', error);
            }
        };

        fetchRandomImage();
        const intervalId = setInterval(fetchRandomImage, 3000); // Fetch new image every 3 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return  <div className="loading-image-container" 
                style={{ backgroundColor: containerBackgroundColor || 'defaultBackgroundColor' }}>
                <div className="loading-bar-background">
                    <div className="loading-bar" style={barStyle}></div>
                </div>
                <div>
                {currentImageUrl && (
                    // Wrap the image with a link to your Instagram profile
                    <a href={instagramProfileUrl} target="_blank" rel="noopener noreferrer">
                        <img src={currentImageUrl} alt="Loading" className="loading-image" />
                    </a>
                )}
                </div>
            </div>
}

export default LoadingBar;