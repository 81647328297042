const GenerateCount = () => {
    const incrementGenerateCount = () => {
      const currentDate = new Date().toLocaleDateString();
      let count = parseInt(localStorage.getItem('generateCount')) || 0;
      const lastDate = localStorage.getItem('generateCountDate');
  
      if (!lastDate || lastDate !== currentDate) {
        count = 0; // Reset count if it's a new day
        localStorage.setItem('generateCountDate', currentDate);
      }
  
      count++;
      localStorage.setItem('generateCount', count);
    };
  
    const getGenerateCount = () => {
      return parseInt(localStorage.getItem('generateCount')) || 0;
    };
  


    const delayByGenerateCount = () => {
        return 0;
        const count = getGenerateCount();
        let delay = 0;

        if (count >= 10 && count < 20) {
            delay = 5;
        } else if (count >= 20 && count < 30) {
            delay = 12;
        } else if (count >= 30 && count < 40) {
            delay = 36;
        } else if (count >= 40 && count < 50) {
            delay = 48;
        } else if (count >= 50 && count < 60) {
            delay = 80;
        } else if (count >= 60 && count < 70) {
            delay = 120;
        } else if (count >= 70 && count < 100) {
            delay = 250;
        } else if (count >= 100) {
            delay = 600;
        }

        // Logic for delaying can be implemented here
        //console.log(`Delaying2 by ${delay} seconds based on generateCount: ${count}`);
        return 0;
    };

    return {
      incrementGenerateCount,
      getGenerateCount,
      delayByGenerateCount
    };
  };
  
  export default GenerateCount;
  