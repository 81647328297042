import React from 'react';
import { IoApps } from 'react-icons/io5';
import './SocialLinks.css';

function SocialLinks() {
    const facebookLink = React.createElement('a', {
        href: 'https://www.facebook.com/profile.php?id=61554717274355',
        target: '_blank',
        rel: 'noopener noreferrer',
        className: 'icon-container'
    },
        React.createElement('i', {className: 'fab fa-facebook-square fa-2x'}),
        React.createElement('span', {className: 'social-text'}, ' 菲娜AI旅行團'),
        React.createElement('div', {className: 'description-box'}, 'Fina3099菲娜AI旅行團')
    );

    const instagramLink = React.createElement('a', {
        href: 'https://www.instagram.com/travelfina3099',
        target: '_blank',
        rel: 'noopener noreferrer',
        className: 'icon-container'
    },
        React.createElement('i', {className: 'fab fa-instagram-square fa-2x'}),
        React.createElement('span', {className: 'social-text'}, ' travelfina3099'),
        React.createElement('div', {className: 'description-box'}, '@travelfina3099')
    );

    const ioAppsLink = React.createElement('a', {
        href: '/',
        target: '_blank',
        rel: 'noopener noreferrer',
        className: 'icon-container' // Added unique class for IoApps
    },
        React.createElement('div', {className: 'icon-hover-container'},
            React.createElement(IoApps, {className: 'fa-2x'})),
        React.createElement('span', {className: 'social-text'}, ' More Apps...'), // Added text span
        React.createElement('div', {className: 'description-box'}, 'More on Fina AI')
    );

    return React.createElement('div', {className: 'social-links'},
        facebookLink,
        instagramLink,
        ioAppsLink
    );
}

export default SocialLinks;
